import { StaticQuery, graphql } from "gatsby"
import React from "react"

import Img from "gatsby-image"

const Services = () => (
  <StaticQuery
    query={graphql`
        query {
            allWordpressAcfServices {
                edges {
                    node {
                        acf {
                            name
                            description
                            image {
                                localFile {
                                    childImageSharp {
                                        sizes(maxWidth: 100) {
                                            ...GatsbyImageSharpSizes
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `}
    render={data => (
        <section class="section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-6">
                        <div class="text-center mb-5 pt-5">
                            <h3 class="title">Our Services</h3>
                        </div>
                    </div>
                </div>

                <div class="row">
                    {data.allWordpressAcfServices.edges.map(service => (
                        <div class="col-lg-3 mt-5">
                            <Img
                                sizes={service.node.acf.image.localFile.childImageSharp.sizes}
                                alt={service.node.acf.name}
                                className="img-responsive"
                                style={{width: `80px`, margin: `0 auto`}}
                            />
                            <h5 className="text-center title mt-3 mb-3">{service.node.acf.name}</h5>
                            <p className="text-center text-muted mb-4">{service.node.acf.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )}
  />
)

export default Services