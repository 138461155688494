import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout.js"
import SEO from "../components/seo"
import Services from "../components/services"

const ServicesPage = ({ data }) => (
  <Layout>
    <SEO
      title={data.wordpressPage.title}
      description={data.wordpressPage.excerpt}
    />
    <div className="container top-heading">
        <div className="row">
            <div className="col-md-6">
                <h1 className="pb-5">{data.wordpressPage.title}</h1>
            </div>
            <div className="col-md-6">
                <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }} />
            </div>
        </div>
        <Services />
    </div>
  </Layout>
)

export default ServicesPage

export const query = graphql`
  query{
    wordpressPage(slug: {eq: "services"}) {
        title
        content
        excerpt
    }
  }
`